<template>
    <div class="OperationPromotion">
        <div class="storeSelection">
            <div class="selectionHeader">
                <div class="selectionItem">考试内容</div>
                <div class="selectionItem">商品信息</div>
                <div class="selectionItem">展现数</div>
                <div class="selectionItem">点击数</div>
                <div class="selectionItem">转化数</div>
                <div class="selectionItem">利润</div>
            </div>
            <div class="selectionForBox" v-if="selectModule.length">
                <div class="selectionBody">
                    <div class="selectionBodyItem">
                        <i class="iconfont" @click="isStoreSelection = !isStoreSelection">{{isStoreSelection ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺选品</span>
                    </div>
                    <div class="selectionBodyItem"></div>
                    <div class="selectionBodyItem">{{display_number}}</div>
                    <div class="selectionBodyItem">{{click_number}}</div>
                    <div class="selectionBodyItem">{{conversion_num}}</div>
                    <div class="selectionBodyItem">{{Number(profit).toFixed(2)}}</div>
                </div>
                <div class="selectionFooter" v-if="isStoreSelection" v-for="(item,index) in selectModule" :key="index">
                    <div class="selectionFooterItem"></div>
                    <div class="selectionFooterItem text-overflow">{{item.name}}</div>
                    <div class="selectionFooterItem">{{item.display_number}}</div>
                    <div class="selectionFooterItem">{{item.click_number}}</div>
                    <div class="selectionFooterItem">{{item.conversion_num}}</div>
                    <div class="selectionFooterItem">{{Number(item.profit).toFixed(2)}}</div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>
        <div class="cut-off"></div>
        <div class="storeMarketing">
            <div class="marketingHeader">
                <div class="marketingItem">考试内容</div>
                <div class="marketingItem">活动类型</div>
                <div class="marketingItem">优惠商品</div>
                <div class="marketingItem">优惠内容</div>
                <div class="marketingItem">得分({{this.$route.query.yx || 0}}%)</div>
            </div>
            <div class="marketingForBox" v-if="StoreMarketing.length">
                <div class="marketingBody">
                    <div class="marketingBodyItem">
                        <i class="iconfont" @click="isMarketing = !isMarketing">{{isMarketing ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺营销</span>
                    </div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem">
                        <span>{{total_score}}</span>分
                    </div>
                </div>
                <div class="marketingFooter" v-for="(item,index) in StoreMarketing" :key="index" v-if="isMarketing">
                    <div class="marketingFooter2" v-for="(item2,index2) in item.activity_data.data" :key="index2" v-if="item2.goods_data">
                        <div class="marketingFooterItem"></div>
                        <div class="marketingFooterItem">{{index2 === 0 ? item.type : ''}}</div>
                        <div class="marketingFooterItem" :title="item2.goods_data.name"><span class="text-overflow">{{item2.goods_data.name}}</span></div>
                        <div class="marketingFooterItem" :title="item.activity_detail || '优惠'+item2.yh_sum+'元'"><span class="text-overflow">{{item.activity_detail || '优惠'+item2.yh_sum+'元'}}</span></div>
                        <div class="marketingFooterItem" v-if="index2 === 0">
                            <span>{{item.score}}</span>分
                        </div>
                        <div class="marketingFooterItem" v-else>

                        </div>
                    </div>
                    <div class="marketingFooter2" v-for="(item3,index3) in item.activity_data.data[0]" :key="index3" v-if="item3.goods_data">
                        <div class="marketingFooterItem"></div>
                        <div class="marketingFooterItem">{{index3 === 0 ? item.type : ''}}</div>
                        <div class="marketingFooterItem" :title="item3.goods_data.name"><span class="text-overflow">{{item3.goods_data.name}}</span></div>
                        <div class="marketingFooterItem" :title="item.activity_detail"><span class="text-overflow">{{item.activity_detail}}</span></div>
                        <div class="marketingFooterItem">
                            <span>{{item.score}}</span>分
                        </div>
                    </div>

                    </div>
            </div>
            <div v-else class="noData">暂无数据</div>
        </div>
        <div class="cut-off"></div>
        <div class="examContent">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
            </div>
            <div class="contentForBox" v-if="boothModule.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isExamContent = !isExamContent">{{isExamContent ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>展位广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{parseFloat(daily_budget)}}</div>
                    <div class="contentBodyItem">{{display_number2}}</div>
                    <div class="contentBodyItem">{{click_number2}}</div>
                    <div class="contentBodyItem">{{conversion_num2}}</div>
                    <div class="contentBodyItem">{{total_cost}}</div>
                </div>
                <div class="contentFooter" v-if="isExamContent" v-for="(item,index) in boothModule" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow">{{item.name}}</div>
                    <div class="contentFooterItem">{{parseFloat(item.daily_budget)}}</div>
                    <div class="contentFooterItem">{{item.display_number}}</div>
                    <div class="contentFooterItem">{{item.click_number}}</div>
                    <div class="contentFooterItem">{{item.conversion_num}}</div>
                    <div class="contentFooterItem">{{Number(item.total_cost).toFixed(2)}}</div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>
        <div class="cut-off"></div>
        <div class="examContent">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
            </div>
            <div class="contentForBox" v-if="searchModuleData.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising = !isSearchAdvertising">{{isSearchAdvertising ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>搜索广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{parseFloat(searchModule.daily_budget)}}</div>
                    <div class="contentBodyItem">{{searchModule.show_num}}</div>
                    <div class="contentBodyItem">{{searchModule.click_num}}</div>
                    <div class="contentBodyItem">{{searchModule.tran_num}}</div>
                    <div class="contentBodyItem">{{searchModule.total_cost}}</div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising" v-for="(item,index) in searchModuleData" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem">{{item.project_name}}</div>
                    <div class="contentFooterItem">{{parseFloat(item.daily_budget)}}</div>
                    <div class="contentFooterItem">{{item.show_num || 0}}</div>
                    <div class="contentFooterItem">{{item.click_num || 0}}</div>
                    <div class="contentFooterItem">{{item.tran_num}}</div>
                    <div class="contentFooterItem">{{item.total_cost || 0}}</div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OperationPromotion",
        data(){
            return {
                isStoreSelection:true,
                isMarketing:true,
                isExamContent:true,
                isSearchAdvertising:true,
                StoreMarketing:[],
                selectModule:[],
                click_number:'',
                conversion_num:'',
                display_number:'',
                profit:'',
                total_score:'',
                boothModule:[],
                click_number2:'',
                conversion_num2:'',
                daily_budget:'',
                display_number2:'',
                total_cost:'',
                searchModule:{},
                searchModuleData:[],
                selectionProfit:0
            }
        },
        created() {
            this.getTableData()
        },
        methods:{
            getTableData(){
              let param = {
                  stu_id:this.$route.query.uid,
                  com_id:this.$route.query.cid,
                  sort:this.$route.query.sort
              }
              this.$http.axiosGetBy(this.$api.getOneStuData, param, res=>{
                  // console.log(189,res);
                  if(res.code === 200){
                      if(res.data.data[0].operation_data){
                          // console.log(193,JSON.parse(res.data.data[0].operation_data))
                          if(JSON.parse(res.data.data[0].operation_data).market_module){
                              this.StoreMarketing = JSON.parse(res.data.data[0].operation_data).market_module.children;
                              this.total_score = JSON.parse(res.data.data[0].operation_data).market_module.total_score || 0;
                          }
                      if(JSON.parse(res.data.data[0].operation_data).select_module){
                          this.selectModule = JSON.parse(res.data.data[0].operation_data).select_module.children;
                          this.click_number = JSON.parse(res.data.data[0].operation_data).select_module.click_number;
                          this.conversion_num = JSON.parse(res.data.data[0].operation_data).select_module.conversion_num;
                          this.display_number = JSON.parse(res.data.data[0].operation_data).select_module.display_number;
                          this.profit = JSON.parse(res.data.data[0].operation_data).select_module.profit;
                          this.selectModule.map(item=>{
                              this.selectionProfit += Number(item.profit)
                          })
                      }
                      if(JSON.parse(res.data.data[0].operation_data).booth_module){
                          this.boothModule = JSON.parse(res.data.data[0].operation_data).booth_module.children;
                          this.click_number2 = JSON.parse(res.data.data[0].operation_data).booth_module.click_number;
                          this.conversion_num2 = JSON.parse(res.data.data[0].operation_data).booth_module.conversion_num;
                          this.daily_budget = JSON.parse(res.data.data[0].operation_data).booth_module.daily_budget;
                          this.display_number2 = JSON.parse(res.data.data[0].operation_data).booth_module.display_number;
                          this.total_cost = JSON.parse(res.data.data[0].operation_data).booth_module.total_cost;
                      }
                      if(JSON.parse(res.data.data[0].operation_data).search_module){
                          this.searchModule = JSON.parse(res.data.data[0].operation_data).search_module;
                          this.searchModuleData = JSON.parse(res.data.data[0].operation_data).search_module.children;
                      }
                      }

                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            // resetData(){
            //     this.$http.axiosGet(this.$api.resetData, res=>{
            //         console.log(230,res)
            //     })
            // },
        }
    }
</script>

<style scoped lang="scss">
    .noData{
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    .cut-off{
        height: 30px;
        background: #F7F9FB;
        width: 100%;
    }
    .OperationPromotion{
        .storeSelection{
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .selectionHeader{
                display: flex;
                background: #F5F5F5;
                height: 60px;
                line-height: 60px;
                .selectionItem{
                    width: 16.6%;
                    text-align: center;
                }
                .selectionItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .selectionItem:nth-child(2){
                    text-align: left;
                    width: 27%;
                }
            }
            .selectionBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .selectionBodyItem{
                    width: 16.6%;
                    text-align: center;
                }
                .selectionBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .selectionBodyItem:nth-child(2){
                    text-align: left;
                    width: 27%;
                }
            }
            .selectionFooter{
                display: flex;
                height: 80px;
                line-height: 80px;
                border-bottom: 1px solid #EEEEEE;
                .selectionFooterItem{
                    width: 16.6%;
                    text-align: center;
                }
                .selectionFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .selectionFooterItem:nth-child(2){
                    text-align: left;
                    width: 27%;
                }
                .selectionFooterItem:nth-child(3){

                }
            }
        }
        .storeMarketing{
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .marketingHeader{
                display: flex;
                background: #F5F5F5;
                height: 60px;
                line-height: 60px;
                .marketingItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                }
            }
            .marketingBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .marketingBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .marketingBodyItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingBodyItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingBodyItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingBodyItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 4px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
            .marketingFooter{
                .marketingFooter2{
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 1px solid #EEEEEE;
                }
                .marketingFooterItem{
                    /*padding: 0 20px;*/
                }
                .marketingFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingFooterItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingFooterItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                    span{
                        display: inline-block;
                        width: 90%;
                    }
                }
                .marketingFooterItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                    span{
                        display: inline-block;
                        width: 80%;
                    }
                }
                .marketingFooterItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 4px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
        }
        .examContent{
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .contentHeader{
                display: flex;
                background: #F5F5F5;
                height: 60px;
                line-height: 60px;
                .contentItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentItem:nth-child(2){
                    text-align: left;
                    width: 32%;
                }
                .contentItem:nth-child(3){
                    text-align: center;
                    width: 12%;
                }
                .contentItem:nth-child(4){
                    text-align: center;
                    width: 12%;
                }
                .contentItem:nth-child(5){
                    text-align: center;
                    width: 12%;
                }
                .contentItem:nth-child(6){
                    text-align: center;
                    width: 12%;
                }
                .contentItem:nth-child(7){
                    text-align: center;
                    width: 12%;
                }
            }
            .contentBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .contentBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .contentBodyItem:nth-child(2){
                    text-align: left;
                    width: 32%;
                }
                .contentBodyItem:nth-child(3){
                    text-align: center;
                    width: 12%;
                }
                .contentBodyItem:nth-child(4){
                    text-align: center;
                    width: 12%;
                }
                .contentBodyItem:nth-child(5){
                    text-align: center;
                    width: 12%;
                }
                .contentBodyItem:nth-child(6){
                    text-align: center;
                    width: 12%;
                }
                .contentBodyItem:nth-child(7){
                    text-align: center;
                    width: 12%;
                }
            }
            .contentFooter{
                display: flex;
                height: 80px;
                line-height: 80px;
                border-bottom: 1px solid #EEEEEE;
                .contentFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentFooterItem:nth-child(2){
                    text-align: left;
                    width: 32%;
                }
                .contentFooterItem:nth-child(3){
                    text-align: center;
                    width: 12%;
                }
                .contentFooterItem:nth-child(4){
                    text-align: center;
                    width: 12%;
                }
                .contentFooterItem:nth-child(5){
                    text-align: center;
                    width: 12%;
                }
                .contentFooterItem:nth-child(6){
                    text-align: center;
                    width: 12%;
                }
                .contentFooterItem:nth-child(7){
                    text-align: center;
                    width: 12%;
                }
            }
        }
    }

</style>