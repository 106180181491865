<template>
  <div class="customer-analysis-content">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item>训练管理</el-breadcrumb-item>
      <el-breadcrumb-item>训练信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="header">{{ activeName }}</div>
    <div class="customer-analysis-tab">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        class="customer-analysis-el-tabs"
      >
        <el-tab-pane label="店铺裝修" name="店铺裝修">
          <StoreDecorate></StoreDecorate>
        </el-tab-pane>
        <el-tab-pane label="客户服务" name="客户服务">
          <CustomerService ref="customerService" />
        </el-tab-pane>
        <el-tab-pane label="运营推广" name="运营推广">
          <OperationPromotion></OperationPromotion>
        </el-tab-pane>
        <el-tab-pane label="分析答辩" name="分析答辩">
          <AnalyticalReply></AnalyticalReply>
        </el-tab-pane>
        <el-tab-pane label="客服数据分析" name="客服数据分析">
          <dataAnalysisService></dataAnalysisService>
        </el-tab-pane>
        <el-tab-pane label="客户服务应答与业务处理" name="客户服务应答与业务处理">
          <ExamStoreCustomer></ExamStoreCustomer>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import StoreDecorate from "../../../components/student/trainManage/StoreDecorate.vue";
import CustomerService from "../../../components/student/trainManage/CustomerService.vue";
import OperationPromotion from "../../../components/student/trainManage/OperationPromotion.vue";
import AnalyticalReply from "../../../components/student/trainManage/AnalyticalReply.vue";
import dataAnalysisService from "../../../components/admin/dataAnalysisService";
import ExamStoreCustomer from "../../../components/admin/ExamStoreCustomer.vue";

export default {
  name: "TrainDetail",
  data() {
    return {
      activeName: "店铺裝修",
    };
  },
  components: {
    StoreDecorate,
    CustomerService,
    OperationPromotion,
    AnalyticalReply,
    dataAnalysisService,
    ExamStoreCustomer
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === "客户服务") {
        this.$refs.customerService.getKfData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.customer-analysis-content {
  min-height: calc(100vh - 120px);
  .header {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #343434;
    margin: 26px 0;
  }
  .customer-analysis-tab {
    min-height: calc(100vh - 246px);
    background-color: #fff;
    /deep/ .customer-analysis-el-tabs {
      min-height: calc(100vh - 246px);
      background-color: #fff;
      .el-tabs__item {
        margin-top: 10px;
        padding-left: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        &.is-active {
          color: #fd4446;
        }
      }
      .el-tabs__active-bar {
        background-color: #fd4446;
      }
      .el-tabs__content {
        /*height: calc(100% - 65px);*/
        .el-tab-pane {
          min-height: calc(100vh - 275px);
        }
      }
      .el-tabs__nav-wrap::after {
        background-color: #fff;
      }
    }
  }
}
</style>
