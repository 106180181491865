<template>
    <div class="customerService">
        <div class="category-table">
            <div class="line head">
                <div class="td">机器人</div>
                <div class="td text-center">提问问题</div>
                <div class="td text-center">提交答案</div>
                <!--                v-if="role !== 2"-->
                <div class="td text-center" style="width: 15%">得分({{kf_score}}%)</div>
                <div class="td text-center" style="width: 15%">平均响应时间</div>
            </div>
            <div class="no-data" v-if="kefuTable.length === 0">暂无答题数据</div>
            <el-scrollbar class="table-body" v-if="kefuTable.length > 0" :native="false">
                <div class="one-level" v-for="(oneItem, oneIndex) in kefuTable" :key="`1categoryList_${oneIndex}`">
                    <div class="line body">
                        <div class="td flex align-center">
                            <i class="iconfont down" :class="{current: oneItem.open}" @click="oneItem.open = !oneItem.open">&#xe8f1;</i>
                            <span>{{oneItem.robot_name}}</span>
                        </div>
                        <div class="td flex align-center"></div>
                        <div class="td flex align-center"></div>
                        <!--                        v-if="role !== 2"-->
                        <div class="td flex align-center" style="width: 15%">
                            <el-input v-model="oneItem.robot_socre" disabled style="width: 70%; margin-right: 10px"></el-input>分
                        </div>
                        <div class="td flex align-center" style="width: 15%">
                            <el-input v-model="oneItem.stu_avg_response_time" disabled style="width: 70%; margin-right: 10px"></el-input>秒
                        </div>
                    </div>
                    <template v-if="oneItem.content.length > 0">
                        <div class="two-level" v-show="oneItem.open">
                            <div class="line body"
                                 v-for="(twoItem, twoIndex) in oneItem.content" :key="`1children_${twoIndex}`">
                                <div class="td flex align-center"></div>
                                <div class="td text-center">
                                    <span>{{twoItem.question}}</span>
                                </div>
                                <div class="td text-center">
                                    <span v-html="twoItem.stu_key_word"></span>
                                </div>
                                <!--                                v-if="role !== 2"-->
                                <div class="td text-center" style="width: 15%">
                                    <el-input v-model="twoItem.text_score" disabled style="width: 70%; margin-right: 10px"></el-input>分
                                </div>
                                <div class="td text-center" style="width: 15%"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </el-scrollbar>
        </div>
        <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "CustomerService",
        data() {
            return {
                kefuTable: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                xlId: Number(this.$route.query.xlid),
                sort: Number(this.$route.query.sort),
                kf_score: Number(this.$route.query.kf)
            }
        },
        methods: {
            //获取客服数据
            getKfData() {
                this.$http.axiosGetBy(this.$api.getOneStuData, {sort: this.sort}, (res) => {
                    if (res.code === 200) {
                        let table = _.find(res.data.data, {id: this.xlId});
                        if (table.kf_data) {
                            this.kefuTable = (JSON.parse(table.kf_data)).com_data.map((item, index) => {
                                if (index === 0) {
                                    item.open = true
                                } else {
                                    item.open = false
                                }
                                return item
                            })
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getTrainList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .operation-pagination {
        padding-right: 30px;
    }
    .customerService{
        height: 100%;
        min-height: calc(100vh - 275px);
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .category-table {
        width: 100%;
        height: 1%;
        flex: 1;
        border: 1px solid #eee;
        .line {
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid #eee;
            &.head {
                height: 60px;
                line-height: 40px;
                background: #F5F5F5;
                color: #13131B;
            }
            &.body {
                /*min-height: 60px;*/
                color: #343441;
            }
            .td {
                width: 17.5%;
                padding: 10px 10px;
                border-left: 1px solid #eee;
                &:first-child {
                    border-left: 0;
                }
                .down {
                    cursor: pointer;
                    color: #7D7D7D;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-right: 4px;
                    font-size: 14px;
                    &.current {
                        transform: rotate(90deg);
                    }
                }
                .placeIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .place24 {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
            &:hover {
                background: #f2f3fa;
            }
        }
        .one-level {
            &:last-child {
                .two-level {
                    .line:last-child {
                        /*border-bottom: 0;*/
                    }
                }
            }
        }
        .two-level {
            transition: all .3s;
        }
        .table-body {
            height: calc(100vh - 327px);
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .align-start {
        align-items: flex-start!important;
    }
    .no-data {
        text-align: center;
        line-height: 60px;
        color: #999;
    }
</style>